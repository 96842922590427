<template>
  <div>
    专车
  </div>
</template>

<script>
  export default {
    name: 'specialcar',
    data(){
      return {

      }
    }
  }
</script>

<style lang="less" scoped>
  @import '../../styles/mixin.less';
  
</style>